import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import API from "./../../Util/API"
import "./BlogPost.css"

export function BlogPost() {
	const { category, id } = useParams()
	const [postData, setPostData] = useState({})
	const api = API()

	useEffect(() => {
		api
			.get(`${category}/${id}`)
			.then((response) => {
				setPostData(response)
			})
			.catch((error) => {
				console.error(error)
			})
	}, [])

	return (
		<React.Fragment>
			<main className="page blog-post">
				<div dangerouslySetInnerHTML={{ __html: postData.content }} />
			</main>
		</React.Fragment>
	)
}
