import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import API from "./../../Util/API"
import Carousel from "react-bootstrap/Carousel"

export function CarouselGridCardsComponent({ path, category }) {
	const [isLoaded, setIsLoaded] = useState(false)
	const [items, setItems] = useState([])
	const [itemsToShow, setItemsToShow] = useState([])
	const navigate = useNavigate()
	const api = API()

	function chunks(arr, n) {
		let chunked = []
		for (let i = 0; i < arr.length; i += n) {
			chunked.push(arr.slice(i, i + n))
		}
		return chunked
	}

	useEffect(() => {
		api
			.get(path)
			.then((response) => {
				setIsLoaded(true)
				setItems(response)
				setItemsToShow(chunks(response, 4))
			})
			.catch((error) => {
				console.error(error)
			})
	}, [])

	const navigateToPost = (item) => {
		navigate(`/post/${category}/${item.id}`)
	}

	return (
		<Carousel id="carousel-2" >
			{itemsToShow.map((chunk, chunkIndex) => (
				<Carousel.Item key={chunkIndex}>
					<div className="row" style={{ padding: "50px" }}>
						{chunk.map((item) => (
							<div className="col-sm-6 item" key={item.id} style={{ padding: "10px" }}>
								<div className="row">
									{item.imageCover && (
										<div className="col-md-12 col-lg-5">
											<a onClick={() => navigateToPost(item)}>
												<img id="carouselImg" className="img-fluid" src={item.imageCover} alt="Carousel item" />
											</a>
										</div>
									)}

									<div className="col">
										<h3 className="name">{item.title}</h3>
										<p className="description">{item.abstract?.substring(0, 500)}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</Carousel.Item>
			))}
		</Carousel>
	)
}
