import React, { useState, useEffect, useRef, useId } from "react"
import { useNavigate, Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import API from "./../../Util/API"
import "./TableAdminComponent.css"

const tdStyle = {
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	maxWidth: "100px",
}

export function TableAdminComponent({ path, categorySingular, categoryPlural, hasArea = false }) {
	const [isLoaded, setIsLoaded] = useState(false)
	const [items, setItems] = useState([])
	const [selectedArea, setSelectedArea] = useState("Investigação criminal")
	const api = API()

	const modalAddButtonRef = useRef(null)

	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	useEffect(() => {
		if (selectedArea && hasArea) {
			api
				.get(`${path}/posts/${selectedArea}`)
				.then((response) => {
					setIsLoaded(true)
					setItems(response)
				})
				.catch((error) => {
					console.error(error)
				})
		} else {
			api
				.get(path)
				.then((response) => {
					setIsLoaded(true)
					setItems(response)
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}, [selectedArea])

	useEffect(() => {
		if (modalAddButtonRef.current) {
			modalAddButtonRef.current.disabled = true
		}
	}, [show])

	useEffect(() => {}, [items])

	function updateSelectedArea(event) {
		setSelectedArea(event.target.value)
	}

	function updateModalSelectedArea(event) {
		if (event.target.value != "0") {
			modalAddButtonRef.current.disabled = false
		} else {
			modalAddButtonRef.current.disabled = true
		}
	}

	const addItem = async () => {
		try {
			let response
			if (hasArea) {
				handleClose()
				response = await api.post(`${path}/${selectedArea}`)
			} else {
				handleClose()
				response = await api.post(path)
			}

			setItems([...items, response])
		} catch (error) {
			console.error(error)
		}
	}

	const deleteItem = async (id) => {
		try {
			api.del(`${path}/${id}`)

			let aux = items.filter((item) => item.id !== id)
			setItems(aux)
			$(".toast").toast({ delay: 2000 })
			$(".toast").toast("show")
		} catch (error) {
			console.error(error)
		}
	}

	if (isLoaded) {
		return (
			<React.Fragment>
				<div>
					<div id="TableSorterCard" className="card">
						<div id="cardHeader" className="card-header py-3">
							<div className="row table-topper align-items-center">
								<div className="col-12 col-sm-5 col-md-3 text-start">
									<p className="m-0 fw-bold">{categoryPlural}</p>
								</div>
								{hasArea && (
									<div className="col-12 col-sm-5 col-md-4">
										<select id="loadProjectsAreaSelector" className="form-select" aria-label="Categoria" onChange={(e) => updateSelectedArea(e)}>
											<option defaultValue="Investigação criminal">Ministério público</option>
											<option value="Justiça estadual">Justiça estadual</option>
											<option value="Segurança pública">Segurança pública</option>
										</select>
									</div>
								)}
								<div className="col-12 col-sm-5 col-md-5 text-end">
									{hasArea ? (
										<button className="btn btn-success" type="button" onClick={handleShow}>
											Adicionar {categorySingular}
										</button>
									) : (
										<button className="btn btn-success" type="button" onClick={() => addItem()}>
											Adicionar {categorySingular}
										</button>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="table-responsive">
									<table id="ipi-table" className="table table tablesorter">
										<thead className="thead-dark">
											<tr>
												<th className="text-center">Título</th>
												<th className="text-center">Resumo</th>
												<th className="text-center">Publicado</th>
												<th className="text-center filter-false sorter-false">Ações</th>
											</tr>
										</thead>
										<tbody className="text-center">
											{items.map((item) => (
												<tr key={item.id}>
													<td className="text-center align-middle" style={tdStyle}>
														{item.title}
													</td>
													<td className="text-center align-middle" style={tdStyle}>
														{item.abstract}
													</td>

													{item.published && (
														<td className="text-center align-middle" style={{ color: "green" }}>
															Sim
														</td>
													)}
													{!item.published && (
														<td className="text-center align-middle" style={{ color: "red" }}>
															Não
														</td>
													)}

													<td className="text-center align-middle">
														<Link className="btn btnMaterial btn-flat success semicircle" role="button" to={`/post/editor/${path}/${item.id}`}>
															<i className="fas fa-pen"></i>
														</Link>

														<a
															className="btn btnMaterial btn-flat accent btnNoBorders checkboxHover"
															role="button"
															data-toggle="modal"
															onClick={() => deleteItem(item.id)}
														>
															<i className="fas fa-trash btnNoBorders"></i>
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{hasArea && (
					<Modal show={show} onHide={handleClose} centered>
						<Modal.Header closeButton>
							<Modal.Title>Escolha de área</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<select
								className="form-select"
								aria-label="Categoria"
								onLoadedData={(e) => updateModalSelectedArea(e)}
								onChange={(e) => updateModalSelectedArea(e)}
							>
								<option value="0">Selecione uma área</option>
								<option value="Investigação criminal">Ministério público</option>
								<option value="Justiça estadual">Justiça estadual</option>
								<option value="Segurança pública">Segurança pública</option>
							</select>
						</Modal.Body>
						<Modal.Footer>
							<Button id="modalAddButton" variant="primary" onClick={() => addItem()} ref={modalAddButtonRef}>
								Adicionar projeto
							</Button>
							<Button variant="secondary" onClick={handleClose}>
								Fechar
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</React.Fragment>
		)
	}
}
