const rawAboutInfo = [
    {
        "name": "Vasco Furtado",
        //"position": "Coordenador",
        "graduationLevel": "Doutor",
        "email": "vasco@unifor.br",
        "lattes": "http://lattes.cnpq.br/3865660008588988",
        "foto": "assets/img/people/Vasco Furtado.gif"
    },
    {
        "name": "Álbero Ítalo Souza dos Santos",
        //"position": "Integrante",
        "graduationLevel": "Bacharelando",
        "email": "playerghost@edu.unifor.br",
        "lattes": "http://lattes.cnpq.br/6549915120590859",
        "foto": "assets/img/people/Álbero Ítalo Souza dos Santos.jpg"
    },
    {
        "name": "André Câmara Ferreira da Costa",
        //"position": "Integrante",
        "graduationLevel": "Mestre",
        "email": "andrecamarafc@gmail.com",
        "lattes": "http://lattes.cnpq.br/3112771925340780",
        "foto": "assets/img/people/André Câmara Ferreira da Costa.gif"
    },
    {
        "name": "André Wescley Oliveira de Aguiar",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "",
        "lattes": "http://lattes.cnpq.br/8514719668141997",
        "foto": "assets/img/people/André Wescley Oliveira de Aguiar.gif"
    },
    {
        "name": "Antônio Maurício Brito Junior",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "",
        "lattes": "http://lattes.cnpq.br/5143360720304253",
        "foto": "assets/img/people/Antônio Maurício Brito Junior.gif"
    },
    {
        "name": "Caio César Ponte Silva",
        //"position": "Integrante",
        "graduationLevel": "Mestre",
        "email": "caioponte@edu.unifor.br",
        "lattes": "http://lattes.cnpq.br/5192584142496105",
        "foto": "assets/img/people/Caio César Ponte Silva.jpg"
    },
    {
        "name": "Carlos de Oliveira Caminha Neto",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "",
        "lattes": "http://lattes.cnpq.br/4380023778677961",
        "foto": "assets/img/people/Carlos de Oliveira Caminha Neto.jpg"
    },
    {
        "name": "Carlos Gustavo",
        //"position": "Integrante",
        "graduationLevel": "Doutorando",
        "email": "carlosgustavo@edu.unifor.br",
        "lattes": "",
        "foto": ""
    },
    {
        "name": "Daniel Dantas Rothéia Carvalho",
        //"position": "Integrante",
        "graduationLevel": "Bacharelando",
        "email": "",
        "lattes": "http://lattes.cnpq.br/0583509217079475",
        "foto": "assets/img/people/Daniel Dantas Rothéia Carvalho.gif"
    },
    {
        "name": "Danilo Cavalcante de Vasconcelos",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "danilovasconcelos@unifor.br",
        "lattes": "http://lattes.cnpq.br/0093722343538766",
        "foto": "assets/img/people/Danilo Cavalcante de Vasconcelos.gif"
    },
    {
        "name": "Eduardo Façanha Dutra",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "",
        "lattes": "",
        "foto": ""
    },
    {
        "name": "Ernesto Pimentel",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "",
        "lattes": "",
        "foto": ""
    },
    {
        "name": "Erneson Alves de Oliveira",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "",
        "lattes": "http://lattes.cnpq.br/7840327783740008",
        "foto": "assets/img/people/Erneson Alves de Oliveira.jpg"
    },
    {
        "name": "Fabio Eduardo de Oliveira Medeiros",
        //"position": "Integrante",
        "graduationLevel": "",
        "email": "feomedeirosdev@gmail.com",
        "lattes": "http://lattes.cnpq.br/6736276625584069",
        "foto": ""
    },
    {
        "name": "Fernando Siqueira",
        //"position": "Integrante",
        "graduationLevel": "Doutorando",
        "email": "fsiqueira@edu.unifor.br",
        "lattes": "http://lattes.cnpq.br/2174168103942844",
        "foto": "assets/img/people/Fernando Siqueira.jpg"
    },
    {
        "name": "Francisca Raquel de Vasconcelos Silveira",
        //"position": "Integrante",
        "graduationLevel": "Doutora",
        "email": "raquel.vsilveira@hotmail.com",
        "lattes": "http://lattes.cnpq.br/7659341014510437",
        "foto": "assets/img/people/Francisca Raquel de Vasconcelos Silveira.gif"
    },
    {
        "name": "Francisco das Chagas Jucá Bomfim",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "fjuca@unifor.br",
        "lattes": "http://lattes.cnpq.br/7125549043372103",
        "foto": "assets/img/people/Francisco das Chagas Jucá Bomfim.gif"
    },
    {
        "name": "Ícaro Luiz Gomes Silva",
        //"position": "Integrante",
        "graduationLevel": "",
        "email": "Icarosoos@edu.unifor.br",
        "lattes": "http://lattes.cnpq.br/8688849406600968",
        "foto": "assets/img/people/Ícaro Luiz Gomes Silva.gif"
    },
    {
        "name": "João Araújo Monteiro Neto",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "joaoneto@unifor.br",
        "lattes": "http://lattes.cnpq.br/4255484163600547",
        "foto": "assets/img/people/João Araújo Monteiro Neto.gif"
    },
    {
        "name": "João Víctor López Matias",
        //"position": "Integrante",
        "graduationLevel": "Bacharelando",
        "email": "jvictorlopez@edu.unifor.br",
        "lattes": "https://lattes.cnpq.br/8063318677023433",
        "foto": "assets/img/people/João Víctor López Matias.jpg"
    },
    {
        "name": "Jorge Luiz Bezerra De Araújo",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "jorgearaujo@unifor.br",
        "lattes": "http://lattes.cnpq.br/7015035716946978",
        "foto": "assets/img/people/Jorge Luiz Bezerra De Araújo.gif"
    },
    {
        "name": "Monica Carvalho Vasconcelos",
        //"position": "Integrante",
        "graduationLevel": "Doutora",
        "email": "",
        "lattes": "http://lattes.cnpq.br/3647717067324357",
        "foto": "assets/img/people/Monica Carvalho Vasconcelos.gif"
    },
    {
        "name": "Nicole Rocha Wirtzbiki",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "",
        "lattes": "http://lattes.cnpq.br/4694699161634448",
        "foto": "assets/img/people/Nicole Rocha Wirtzbiki.gif"
    },
    {
        "name": "Paulo Ubirajara de Mattos Neto",
        //"position": "Integrante",
        "graduationLevel": "",
        "email": "",
        "lattes": "http://lattes.cnpq.br/7427552859502676",
        "foto": "assets/img/people/Paulo Ubirajara de Mattos Neto.gif"
    },
    {
        "name": "Renato Vidal Moura",
        //"position": "Integrante",
        "graduationLevel": "Bacharelando",
        "email": "renatovidal__@hotmail.com",
        "lattes": "http://lattes.cnpq.br/9202418119486315",
        "foto": "assets/img/people/Renato Vidal Moura.jpg"
    },
    {
        "name": "Ricardo Bezerra de Menezes Guedes",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "ricardobmg@gmail.com",
        "lattes": "http://lattes.cnpq.br/4186661410301575",
        "foto": "assets/img/people/Ricardo Bezerra de Menezes Guedes.gif"
    },
    {
        "name": "Ricardo Martins Cordeiro",
        //"position": "Integrante",
        "graduationLevel": "",
        "email": "ricardo.martins.6373@gmail.com",
        "lattes": "http://lattes.cnpq.br/1295101148178644",
        "foto": "assets/img/people/Ricardo Martins Cordeiro.gif"
    },
    {
        "name": "Rilder de Souza Pires",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "rilder@unifor.br",
        "lattes": "http://lattes.cnpq.br/4214634077827179",
        "foto": "assets/img/people/Rilder de Souza Pires.gif"
    },
    {
        "name": "Rommel Dias Saraiva",
        //"position": "Integrante",
        "graduationLevel": "Doutor",
        "email": "",
        "lattes": "http://lattes.cnpq.br/4527686499680864",
        "foto": "assets/img/people/Rommel Dias Saraiva.gif"
    },
    {
        "name": "Tiago Ponte Carvalho Vasconcelos",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "",
        "lattes": "",
        "foto": ""
    },
    {
        "name": "Torricelli Evangelista Cavalcante De Sousa",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "",
        "lattes": "",
        "foto": ""
    },
    {
        "name": "Vitor Freitas de Almeida",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "almeida.f.vitor@gmail.com",
        "lattes": "",
        "foto": ""
    },
    {
        "name": "Vladia Pinheiro",
        //"position": "Integrante",
        "graduationLevel": "Doutora",
        "email": "vladiacelia@unifor.br",
        "lattes": "http://lattes.cnpq.br/2991281565518934",
        "foto": "assets/img/people/Vladia Pinheiro.jpg"
    },
    {
        "name": "Erik Zarko Macêdo Solha",
        //"position": "Integrante",
        "graduationLevel": "Mestrando",
        "email": "erik.solha.ms@edu.unifor.br",
        "lattes": "https://lattes.cnpq.br/6839995916656983",
        "foto": "assets/img/people/Erik Zarko Macêdo Solha.jpg"
    }
]

const graduation_order = ['Doutora', 'Doutor', 'Doutorando', 'Mestre', 'Mestrando', 'Bacharelando']

function sortAboutInfo(aboutInfo) {
    aboutInfo.sort((a, b) => {
        // Check if position is "Coordenador"
        // if (a.position === "Coordenador" && b.position !== "Coordenador") return -1
        // if (a.position !== "Coordenador" && b.position === "Coordenador") return 1

        // Priority for names "Vladia" or "Vasco"
        const priorityNames = ['Vasco Furtado', 'Vladia Pinheiro']
        const namePriorityA = priorityNames.includes(a.name) ? 0 : 1
        const namePriorityB = priorityNames.includes(b.name) ? 0 : 1

        if (namePriorityA < namePriorityB) return -1
        if (namePriorityA > namePriorityB) return 1

        let indexA = graduation_order.indexOf(a.graduationLevel)
        let indexB = graduation_order.indexOf(b.graduationLevel)

        // Consider 'Doutor' and 'Doutora' as the same level
        if (a.graduationLevel === 'Doutora') indexA = graduation_order.indexOf('Doutor')
        if (b.graduationLevel === 'Doutora') indexB = graduation_order.indexOf('Doutor')

        if (indexA === -1) indexA = Infinity // if not found, assign Infinity
        if (indexB === -1) indexB = Infinity // if not found, assign Infinity

        // Compare by graduationLevel
        if (indexA < indexB) return -1
        if (indexA > indexB) return 1

        // If graduationLevel is the same, compare by name
        return a.name.localeCompare(b.name)
    })

    return aboutInfo
}

export const aboutInfo = sortAboutInfo(rawAboutInfo)