import React from "react"
import "./About.css"
import { aboutInfo } from "../../Util/People.js"

export function About() {
	return (
		<React.Fragment>
			<main className="page">
				<section className="clean-block about-us">
					<div className="container">
						<div className="block-heading">
							<h2 className="text-info">Sobre nós</h2>
							<p>Conheça nossa equipe de orientadores e estudantes.</p>
						</div>
						<div className="row">
							{aboutInfo.map((person, index) => (
								<div className="col-sm-6 col-lg-4 d-flex mb-3" key={index}>
									<div className="card text-center clean-card d-flex flex-column">
										{person.foto && (
											<div className="image-container">
												<img id="person-image" className="card-img-top w-100 d-block" src={person.foto} alt={person.name} />
											</div>
										)}
										<div className="card-body info flex-fill d-flex flex-column justify-content-center">
											<h2 className="card-title fs-5">{person.name}</h2>
											{person.position && (
												<div className="d-flex card-text">
													<span>Cargo:&nbsp;</span> <span>{person.position}</span>
												</div>
											)}
											{person.graduationLevel && (
												<div className="d-flex card-text">
													<span>Titulação:&nbsp;</span> <span>{person.graduationLevel}</span>
												</div>
											)}
											{person.email && (
												<div className="d-flex card-text">
													<span>Email:&nbsp;</span> <span>{person.email}</span>
												</div>
											)}
											{person.lattes && (
												<div className="d-flex card-text">
													<span>Lattes:&nbsp;</span>
													<a href={person.lattes} id="cardTextLattes" className="card-text" >
														{person.lattes}
													</a>
												</div>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
			</main>
		</React.Fragment>
	)
}
