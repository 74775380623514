import React from "react"
import { Link } from "react-router-dom"

export function NavbarFixedTop() {
	return (
		<React.Fragment>
			<nav id="indexNavbar" className="navbar navbar-light navbar-expand-lg fixed-top clean-navbar">
				<div className="container-fluid">
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent">
						<span className="visually-hidden">Toggle navigation</span>
						<span className="navbar-toggler-icon text-white"></span>
					</button>
					<div className="collapse navbar-collapse text-center" id="navbarContent">
						<ul className="navbar-nav navbar-nav-scroll mx-auto">
							<li className="nav-item">
								<Link to="/" id="a" className="nav-link active">
									Início
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/projects" id="a" className="nav-link">
									Projetos
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/publications" id="a" className="nav-link">
									Publicações<br></br>
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/resources" id="a" className="nav-link">
									Recursos<br></br>
								</Link>
							</li>
							{/* <li className="nav-item">
								<Link to="/faq" id="a" className="nav-link">
									FAQ
								</Link>
							</li> */}
							{/* <li className="nav-item">
								<Link to="/contact" id="a" className="nav-link">
									Contato
								</Link>
							</li> */}
							<li className="nav-item">
								<Link to="/about" id="a" className="nav-link">
									Sobre nós
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</React.Fragment>
	)
}
