import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"
import API from "./../../Util/API"
import Carousel from "react-bootstrap/Carousel"

export function CarouselComponent({ path, category }) {
	const [isLoaded, setIsLoaded] = useState(false)
	const [items, setItems] = useState([])
	const navigate = useNavigate()
	const api = API()

	useEffect(() => {
		api
			.get(path)
			.then((response) => {
				setIsLoaded(true)
				setItems(response)
			})
			.catch((error) => {
				console.error(error)
			})
	}, [])

	const navigateToPost = (item) => {
		navigate(`/post/${category}/${item.id}`)
	}

	return (
		<React.Fragment>
			<Carousel id="carousel-1">
				{items.map(
					(item, index) =>
						item.imageCover && (
							<Carousel.Item key={item.id}>
								<img id="carouselImg" className="d-block w-100" src={item.imageCover} alt="Slide Image" onClick={() => navigateToPost(item)} />
								<Carousel.Caption>
									<h2>{item.title}</h2>
									<p className="text-white">{item.abstract}</p>
								</Carousel.Caption>
							</Carousel.Item>
						)
				)}
			</Carousel>
		</React.Fragment>
	)
}
