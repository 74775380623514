import { useAuth0 } from "@auth0/auth0-react"

const API = () => {
    const { getAccessTokenSilently } = useAuth0()

    const getAccessToken = async () => {
        const accessToken = encodeURIComponent(await getAccessTokenSilently())
        return accessToken
    }

    const baseURL = (() => {
        const currentURL = window.location.href
        const defaultBaseURL = "https://ncdia.org/api" // Replace with your default API base URL

        if (currentURL.includes("18.214.121.114")) {
            return "https://18.214.121.114"
        }

        return defaultBaseURL
    })()

    const get = async (endpoint) => {
        try {
            const response = await fetch(`${baseURL}/${endpoint}`)
            if (!response.ok) throw new Error("Failed to fetch data from API")
            return response.json()
        } catch (error) {
            console.error(error)
            throw new Error("Failed to fetch data from API")
        }
    }

    const post = async (endpoint, data = {}) => {
        try {
            const token = await getAccessToken()

            const response = await fetch(`${baseURL}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (!response.ok) throw new Error("Failed to post data to API")

            return response.json()
        } catch (error) {
            console.error(error)
            throw new Error("Failed to post data to API")
        }
    }

    const del = async (endpoint) => {
        try {
            const token = await getAccessToken()

            const response = await fetch(`${baseURL}/${endpoint}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })

            if (!response.ok) throw new Error("Failed to delete data from API")
        } catch (error) {
            console.error(error)
            throw new Error("Failed to delete data from API")
        }
    }

    const put = async (endpoint, data = {}) => {
        try {
            const token = await getAccessToken()

            const response = await fetch(`${baseURL}/${endpoint}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (!response.ok) throw new Error("Failed to update data in API")
        } catch (error) {
            console.error(error)
            throw new Error("Failed to update data in API")
        }
    }

    return { baseURL, get, post, del, put }
}

export default API