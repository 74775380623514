import React, { useState, useEffect, useRef } from "react"
import { TableAdminComponent } from "../../components/TableAdminComponent/TableAdminComponent"
import LogoutButton from "../../components/Auth/LogoutButton"
import "./Ludens---1-Dark-mode-Index-Table-with-Search-Filters.css"
import SplitPane from "react-split-pane"

export function AdminHome() {
	document.getElementById("indexNavbar").classList.remove("fixed-top")

	return (
		<React.Fragment>
			<div id="adminHomeContainer" className="container-fluid d-flex">
				<SplitPane className="splitter-pane" split="vertical" minSize={10} defaultSize={$(window).width() / 3}>
					<TableAdminComponent path="projects" categorySingular="Projeto" categoryPlural="Projetos" hasArea={true}></TableAdminComponent>
					<TableAdminComponent path="publications" categorySingular="publicação" categoryPlural="Publicações"></TableAdminComponent>
					<TableAdminComponent path="resources" categorySingular="Recurso" categoryPlural="Recursos"></TableAdminComponent>
				</SplitPane>
			</div>

			<LogoutButton />
		</React.Fragment>
	)
}
