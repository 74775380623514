import React from "react"
import { Button } from "react-bootstrap"
import { FaUserSlash } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react"

const LogoutButton = () => {
	const { logout } = useAuth0()

	return (
		<div className="position-fixed top-0 end-0 m-3">
			<Button type="button" className="btn btn-danger" variant="primary" onClick={() => logout({ returnTo: window.location.origin })}>
				<FaUserSlash />
			</Button>
		</div>
	)
}

export default LogoutButton
