import React, { useState, useEffect } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import API from "./../../Util/API"
import "./Posts.css"

export function Posts({ title, path }) {
	const [isLoaded, setIsLoaded] = useState(false)
	const [items, setItems] = useState([])
	const location = useLocation()
	const { area } = useParams()
	console.log(area)
	const api = API()

	const [selectedItemId, setSelectedItemId] = useState("")

	const [show, setShow] = useState(false)
	const handleShow = (itemID) => {
		setSelectedItemId(itemID)
		setShow(true)
	}

	const handleClose = () => setShow(false)

	useEffect(() => {
		if (area) {
			api
				.get(`published/${path}/posts/${area}`)
				.then((response) => {
					setIsLoaded(true)
					setItems(response)
				})
				.catch((error) => {
					console.error(error)
				})
		} else {
			if (title === "Projetos") {
				api
					.get(`published/${path}/areas`)
					.then((response) => {
						setIsLoaded(true)
						setItems(response)
					})
					.catch((error) => {
						console.error(error)
					})
			} else {
				api
					.get(`published/${path}`)
					.then((response) => {
						setIsLoaded(true)
						setItems(response)
					})
					.catch((error) => {
						console.error(error)
					})
			}
		}
	}, [path, location.pathname])

	return (
		<React.Fragment>
			<main className="page blog-post-list">
				<section className="clean-block clean-blog-list dark">
					<div className="container">
						<div className="block-heading">
							<h2 className="text-info">
								{title}
								<br></br>
							</h2>
						</div>
						<div className="block-content">
							{items.map((item) => {
								if (title == "Projetos") {
									if (area) {
										return (
											<div className="clean-blog-post" key={item.id}>
												<div className="row">
													<div className="col-lg-7">
														<h3>{item.title}</h3>
														<p>{item.abstract}</p>
														<Link to={`/post/${path}/${item.id}`} className="btn btn-secondary" role="button">
															Ver mais
														</Link>
													</div>

													{item.imageCover != "" && (
														<div className="col-lg-5">
															<img className="rounded img-fluid" src={item.imageCover}></img>
														</div>
													)}
												</div>
											</div>
										)
									} else {
										if (typeof item == "string") {
											return (
												<div className="clean-blog-post" key={item}>
													<div className="row">
														<div className="row">
															<div className="col-lg-7">
																<h3>{item}</h3>
																<Link to={`/${path}/${item}`} state={{ area: item }} className="btn btn-secondary" role="button">
																	Ver mais
																</Link>
															</div>
														</div>
													</div>
												</div>
											)
										}
									}
								} else {
									return (
										<div className="clean-blog-post" key={item.id}>
											<div className="row">
												<div className="col-lg-7">
													<h3>{item.title}</h3>
													<p>{item.abstract}</p>
													{item.pdfName ? (
														<>
															<button onClick={() => handleShow(item.id)} className="btn btn-secondary" role="button">
																Ver mais
															</button>

															<Modal show={show} onHide={handleClose} size="xl">
																<Modal.Header closeButton>
																	<Modal.Title style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>Visualizador de PDF</Modal.Title>
																</Modal.Header>
																<Modal.Body>
																	<embed
																		src={`${api.baseURL}/published/${path}/${selectedItemId}/pdf`}
																		type="application/pdf"
																		style={{ width: "100%", height: "80vh" }}
																	/>
																</Modal.Body>
															</Modal>
														</>
													) : (
														<Link to={`/post/${path}/${item.id}`} className="btn btn-secondary" role="button">
															Ver mais
														</Link>
													)}
												</div>

												{item.imageCover != "" && (
													<div className="col-lg-5">
														<img className="rounded img-fluid" src={item.imageCover}></img>
													</div>
												)}
											</div>
										</div>
									)
								}
							})}
						</div>
					</div>
				</section>
			</main>
		</React.Fragment>
	)
}
