import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Editor } from "@tinymce/tinymce-react"
import SplitPane from "react-split-pane"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import API from "./../../Util/API"
import "./BlogPostEditor.css"

const previewStyle = {
	overflowY: "scroll",
	blockSize: "100vh",
}

export function BlogPostEditor() {
	const { category, id } = useParams()
	const postDataRef = useRef(null)
	const [editorHtml, setEditorHtml] = useState(null)
	const editorHtmlRef = useRef(editorHtml)
	const [imageCover, setImageCover] = useState(null)

	const path = category
	const pdfNameRef = useRef(null)
	const pdfRef = useRef(null)
	const [pdfUrl, setPdfUrl] = useState(null)

	const [showTitleModal, setShowTitleModal] = useState(false)
	const handleCloseTitleModal = () => setShowTitleModal(false)
	const handleShowTitleModal = () => setShowTitleModal(true)

	const [showAbstractModal, setShowAbstractModal] = useState(false)
	const handleCloseAbstractModal = () => setShowAbstractModal(false)
	const handleShowAbstractModal = () => setShowAbstractModal(true)

	const [showImageModal, setShowImageModal] = useState(false)
	const handleCloseImageModal = () => setShowImageModal(false)
	const handleShowImageModal = () => setShowImageModal(true)

	const titleFormValueRef = useRef(null),
		onTitleFormInput = ({ target: { value } }) => (titleFormValueRef.current = value)

	const abstractFormValueRef = useRef(null),
		onAbstractFormInput = ({ target: { value } }) => (abstractFormValueRef.current = value)

	const [showPdfMode, setShowPdfMode] = useState(false)
	const [showTextEditorMode, setShowTextEditorMode] = useState(false)

	const api = API()

	useEffect(() => {
		api
			.get(`${category}/${id}`)
			.then((response) => {
				if (response.mode == "pdf") {
					setShowPdfMode(true)
					setShowTextEditorMode(false)
				} else {
					setShowPdfMode(false)
					setShowTextEditorMode(true)
				}

				postDataRef.current = response
				setImageCover(response.imageCover)
				titleFormValueRef.current = response.title
				abstractFormValueRef.current = response.abstract
				pdfNameRef.current = response.pdfName
				pdfRef.current = response.pdf

				if (response.pdf) setPdfUrl(pdfUrlFromBlobString(response.pdf))

				setEditorHtml(response.content)
				editorHtmlRef.current = response.content
			})
			.catch((error) => {
				console.error(error)
			})
	}, [])

	function saveLoadedImageBlob(image) {
		var reader = new FileReader()

		reader.onload = function () {
			setImageCover(reader.result)
		}

		reader.readAsDataURL(image)
	}

	function saveTitle() {
		postDataRef.current.title = titleFormValueRef.current
		setShowTitleModal(false)

		api.put(`${path}/${id}`, postDataRef.current).catch((error) => {
			console.error(error)
		})
	}

	function saveAbstract() {
		postDataRef.current.abstract = abstractFormValueRef.current
		setShowAbstractModal(false)

		api.put(`${path}/${id}`, postDataRef.current).catch((error) => {
			console.error(error)
		})
	}

	function saveImageCover() {
		postDataRef.current.imageCover = imageCover
		setShowImageModal(false)

		api.put(`${path}/${id}`, postDataRef.current).catch((error) => {
			console.error(error)
		})
	}

	function removeImageCover() {
		setImageCover("")
		saveImageCover()
	}

	function saveContent() {
		if (showPdfMode) {
			postDataRef.current.pdfName = pdfNameRef.current
			postDataRef.current.pdf = pdfRef.current

			postDataRef.current.mode = "pdf"
		}

		if (showTextEditorMode) {
			postDataRef.current.content = editorHtml
			postDataRef.current.pdfName = ""
			postDataRef.current.pdf = ""

			postDataRef.current.mode = "text"
		}

		api.put(`${path}/${id}`, postDataRef.current).catch((error) => {
			console.error(error)
		})
	}

	function removePDF() {
		pdfNameRef.current = ""
		pdfRef.current = ""
		setPdfUrl("")
	}

	function publishData() {
		postDataRef.current.published = true

		api.put(`${path}/${id}`, postDataRef.current).catch((error) => {
			console.error(error)
		})
	}

	function unpublishData() {
		postDataRef.current.published = false

		api.put(`${path}/${id}`, postDataRef.current).catch((error) => {
			console.error(error)
		})
	}

	function uploadPDF(event) {
		if (event.target.files.length > 0) {
			let file = event.target.files[0]

			let reader = new FileReader()

			reader.onload = function () {
				let base64String = reader.result
				pdfNameRef.current = file.name
				pdfRef.current = base64String
				setPdfUrl(pdfUrlFromBlobString(base64String))
			}

			reader.readAsDataURL(file)
		}
	}

	function pdfUrlFromBlobString(blobString) {
		let base64String = blobString.replace("data:application/pdf;base64,", "")
		let binaryString = window.atob(base64String)
		let binaryLen = binaryString.length
		let bytes = new Uint8Array(binaryLen)

		for (let i = 0; i < binaryLen; i++) {
			let ascii = binaryString.charCodeAt(i)
			bytes[i] = ascii
		}

		let blob = new Blob([bytes], { type: "application/pdf" })
		return URL.createObjectURL(blob)
	}

	function useInterval(callback, delay) {
		const savedCallback = useRef()

		// Remember the latest callback.
		useEffect(() => {
			savedCallback.current = callback
		}, [callback])

		// Set up the interval.
		useEffect(() => {
			function tick() {
				savedCallback.current()
			}
			if (delay !== null) {
				let id = setInterval(tick, delay)
				return () => clearInterval(id)
			}
		}, [delay])
	}

	useInterval(() => {
		saveContent()
	}, 10000)

	function file_picker_callback(cb, value, meta) {
		var input = document.createElement("input")
		input.setAttribute("type", "file")
		input.setAttribute("accept", "image/*")

		input.onchange = function () {
			var file = this.files[0]

			var reader = new FileReader()
			reader.onload = function () {
				var id = "blobid" + new Date().getTime()
				var blobCache = tinymce.activeEditor.editorUpload.blobCache
				var base64 = reader.result.split(",")[1]
				var blobInfo = blobCache.create(id, file, base64)
				blobCache.add(blobInfo)

				/* call the callback and populate the Title field with the file name */
				cb(blobInfo.blobUri(), { title: file.name })
			}
			reader.readAsDataURL(file)
		}

		input.click()
	}

	function setEditMode(editMode) {
		if (editMode == "pdf") {
			setShowPdfMode(true)
			setShowTextEditorMode(false)
		}
		if (editMode == "text") {
			setShowPdfMode(false)
			setShowTextEditorMode(true)
		}
	}

	return (
		<>
			<nav id="indexNavbarMetadata" className="navbar navbar-light navbar-expand-lg clean-navbar">
				<div className="container-fluid">
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent">
						<span className="visually-hidden">Toggle navigation</span>
						<span className="navbar-toggler-icon text-white"></span>
					</button>
					<div className="collapse navbar-collapse text-center" id="navbarContent">
						<ul className="navbar-nav navbar-nav-scroll mx-auto">
							<li className="nav-item">
								<a id="a" className="nav-link active" type="button" onClick={handleShowTitleModal}>
									Editar Título
								</a>
							</li>
							<li className="nav-item">
								<a id="a" className="nav-link" type="button" onClick={handleShowAbstractModal}>
									Editar Resumo
								</a>
							</li>
							<li className="nav-item">
								<a id="a" className="nav-link" type="button" onClick={handleShowImageModal}>
									Editar Imagem de preview
								</a>
							</li>
							<li className="nav-item">
								<a id="a" className="nav-link" type="button" onClick={() => saveContent()}>
									Salvar<br></br>
								</a>
							</li>

							{category == "publications" && (
								<li className="nav-item">
									<a id="a" className="nav-link" type="button" onClick={() => setEditMode("pdf")}>
										Modo PDF<br></br>
									</a>
								</li>
							)}
							{category == "publications" && (
								<li className="nav-item">
									<a id="a" className="nav-link" type="button" onClick={() => setEditMode("text")}>
										Modo Editor de texto<br></br>
									</a>
								</li>
							)}

							{category == "publications" && (
								<li className="nav-item">
									<a id="a" className="nav-link" type="button" onClick={() => removePDF()}>
										Remover PDF<br></br>
									</a>
								</li>
							)}

							<li className="nav-item">
								<a id="a" className="nav-link" type="button" onClick={() => publishData()}>
									Publicar
								</a>
							</li>

							<li className="nav-item">
								<a id="a" className="nav-link" type="button" onClick={() => unpublishData()}>
									Tirar do ar
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			{showTextEditorMode && (
				<SplitPane className="splitter-pane" split="vertical" minSize={10} defaultSize={$(window).width() / 2}>
					<div className="editor-container">
						<Editor
							id="tinyMCE"
							init={{
								selector: "textarea",
								plugins: [
									"a11ychecker",
									"advlist",
									"advcode",
									"advtable",
									"autolink",
									"checklist",
									"export",
									"lists",
									"link",
									"image",
									"charmap",
									"preview",
									"anchor",
									"searchreplace",
									"visualblocks",
									"powerpaste",
									"fullscreen",
									"formatpainter",
									"insertdatetime",
									"table",
									"help",
									"wordcount",
								],
								toolbar:
									"undo redo | casechange blocks | bold italic backcolor | " +
									"alignleft aligncenter alignright alignjustify | " +
									"bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
								content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
								language: "pt_BR",
								image_title: true,
								automatic_uploads: true,
								file_picker_callback: file_picker_callback,
							}}
							onInit={(evt, editor) => {
								editor.on("Paste Change input Undo Redo", () => {
									setEditorHtml(editor.getContent())
								})
							}}
							initialValue={editorHtmlRef.current}
						/>
					</div>
					<div dangerouslySetInnerHTML={{ __html: editorHtml }} style={previewStyle} />
				</SplitPane>
			)}

			{showPdfMode && (
				<div className="d-flex justify-content-center align-items-center" style={{ height: "84.7vh", width: "99.3vw" }}>
					{!pdfUrl ? (
						<input type="file" id="pdfFileInput" name="pdfFile" accept="application/pdf" onChange={(event) => uploadPDF(event)} />
					) : (
						<iframe src={pdfUrl} style={{ border: "none", width: "100%", height: "100%" }}></iframe>
					)}
				</div>
			)}

			<Modal show={showTitleModal} onHide={handleCloseTitleModal} backdrop="static" keyboard={false}>
				<Modal.Header className="px-4" closeButton>
					<Modal.Title className="ms-auto">Editar Título</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="titleForm.ControlInput">
							<Form.Label>Informe um título:</Form.Label>
							<Form.Control type="text" placeholder="Título" onChange={onTitleFormInput} defaultValue={titleFormValueRef.current} autoFocus />
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => saveTitle()}>
						Salvar
					</Button>
					<Button variant="secondary" onClick={handleCloseTitleModal}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal id="resumeModal" size="lg" show={showAbstractModal} onHide={handleCloseAbstractModal} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Editar Resumo</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="abstractForm.ControlTextarea">
							<Form.Label>Informe um resumo para o post:</Form.Label>
							<Form.Control
								as="textarea"
								rows={5}
								placeholder="Resumo"
								onChange={onAbstractFormInput}
								defaultValue={abstractFormValueRef.current}
								autoFocus
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => saveAbstract()}>
						Salvar
					</Button>
					<Button variant="secondary" onClick={handleCloseAbstractModal}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showImageModal} onHide={handleCloseImageModal} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Editar Imagem de preview</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{imageCover != "" && <img src={imageCover} alt="image cove preview" />}

					<form>
						<input type="file" onChange={(e) => saveLoadedImageBlob(e.target.files[0])} autoFocus />
					</form>
					<Button id="removeImageButton" variant="danger" onClick={() => removeImageCover()}>
						Remover imagem
					</Button>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => saveImageCover()}>
						Salvar
					</Button>
					<Button variant="secondary" onClick={handleCloseImageModal}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
