import React, { useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import { Home, Projects, Publications, Posts, FAQ, Contact, About, LoginRedirect, AdminHome, BlogPostEditor, BlogPost } from "./pages"
import { NavbarFixedTop } from "./components/NavBarFixedTop/NavBarFixedTop"
import { ProtectedRoute } from "./components/Auth/ProtectedRoute"

function usePageViews() {
	let location = useLocation()

	useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			window.gtag("config", process.env.REACT_APP_TRACKING_ID, {
				page_path: location.pathname,
				send_page_view: true,
			})
		}
	}, [location])
}

export default function App() {
	usePageViews()

	return (
		<React.Fragment>
			<NavbarFixedTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="projects" element={<Posts title="Projetos" path="projects" />} />
				<Route path="projects/:area" element={<Posts title="Projetos" path="projects" />} />
				<Route path="publications" element={<Posts title="Publicações" path="publications" />} />
				<Route path="resources" element={<Posts title="Recursos" path="resources" />} />
				<Route path="post/:category/:id" element={<BlogPost />} />
				<Route path="post/:category/:area/:id" element={<BlogPost />} />
				{/* <Route path="faq" element={<FAQ />} /> */}
				{/* <Route path="contact" element={<Contact />} /> */}
				<Route path="about" element={<About />} />
				<Route path="adminHome" element={<ProtectedRoute component={AdminHome} />} />
				<Route path="post/editor/:category/:id" element={<ProtectedRoute component={BlogPostEditor} />} />
			</Routes>
		</React.Fragment>
	)
}
