import React from "react"
import { Link } from "react-router-dom"
import { CarouselComponent } from "../../components/Carousel/Carousel"
import { CarouselGridCardsComponent } from "../../components/CarouselGridCards/CarouselGridCards"
import "./Home.css"
import "./Projects-Horizontal.css"

const styles = {
	maxWidth: "5px",
}

export function Home() {
	return (
		<React.Fragment>
			<main className="page landing-page">
				<section className="text-black-50 clean-block clean-hero">
					<div className="d-inline-flex flex-fill justify-content-between text">
						<h1>Ciência de dados e inteligência artificial na justiça e segurança pública</h1>
					</div>
				</section>
				<section id="section" className="clean-block clean-info dark">
					<div className="container">
						<div className="block-heading">
							<h2 className="text-info">Descrição</h2>
							<p>
								O Núcleo de Ciência de Dados e Inteligência Artificial (NCDIA) da Universidade de Fortaleza, da Fundação Edson Queiroz, é um ambiente
								dedicado ao desenvolvimento de projetos de pesquisa e inovação através da análise de dados, da excelência de modelos matemáticos,
								estatísticos e computacionais, e da aplicação e aprimoramento de técnicas de inteligência artificial, utilizando processamento de
								linguagem natural, aprendizado de máquina e aprendizado profundo.
							</p>
						</div>
					</div>
				</section>
				<section className="clean-block features">
					<div className="container">
						<div className="block-heading">
							<h2 className="text-info">Áreas de atuação</h2>
						</div>
						<div className="row">
							<div className="col-md-5 feature-box" style={styles}>
								<i className="icon-graduation icon"></i>
								<h4>Ciência de dados</h4>
							</div>
							<div className="col-md-5 feature-box" style={styles}>
								<i className="icon-pencil icon"></i>
								<h4>Inteligência artificial</h4>
							</div>
							<div className="col-md-5 feature-box" style={styles}>
								<i className="icon-organization icon"></i>
								<h4>Redes Complexas</h4>
							</div>
							<div className="col-md-5 feature-box" style={styles}>
								<i className="icon-refresh icon"></i>
								<h4>Mineração de Processos</h4>
							</div>
						</div>
					</div>
				</section>
				<section id="section" className="clean-block slider dark">
					<div className="container">
						<div className="block-heading">
							<h2 className="text-info">Recursos principais</h2>
						</div>
						<CarouselComponent path="published/resources" category="resources"></CarouselComponent>
					</div>
				</section>
				<section id="section" className="projects-horizontal">
					<div className="container">
						<div className="intro">
							<h2 className="text-center">Publicações</h2>
						</div>
					</div>
					<CarouselGridCardsComponent path="published/publications" category="publications"></CarouselGridCardsComponent>
				</section>
			</main>
		</React.Fragment>
	)
}
