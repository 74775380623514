import React from "react"
import { useNavigate } from "react-router-dom"
import { Auth0Provider } from "@auth0/auth0-react"

const Auth0ProviderWithHistory = ({ children }) => {
	const domain = process.env.REACT_APP_AUTH0_DOMAIN
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

	const navigate = useNavigate()

	const onRedirectCallback = (appState) => {
		navigate((appState && appState.returnTo) || window.location.pathname)
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			redirectUri={window.top.location.origin}
			onRedirectCallback={onRedirectCallback}
		>
			{children}
		</Auth0Provider>
	)
}

export default Auth0ProviderWithHistory
